import React, { FC, useMemo } from 'react'
import { Column } from 'react-table'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import CustomTable from '../../../../core/components/table/Table'
import { useApplicationsContext } from '../state/useApplications'
import StatusBadge, { TActionMarker } from '../../../../core/components/StatusBadge'
import { TApplication, TStatus } from '../types'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import NoDataComponent, { ContainerStyled } from '../../../../core/components/NoDataComponent'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import { statusToHumanReadableFormat } from '../../../../core/utils/statusToHumanReadableFormat'
import Spinner from '../../../../core/components/Spinner'
import { HOUR_MINUTE, SLASH_DATE_FORMAT } from '../../../../core/const/date'
import NoResults from '../../../../core/components/NoResults'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'
import IdentityIcon from '../../applicationDetail/components/IdentityIcon'
import IdentityInfo from './IdentityInfo'

const markerMap: Record<TStatus, TActionMarker> = {
  NEW: 'neutral',
  APPROVED: 'positive',
  DECLINED: 'negative',
  CANCELLED: 'negative',
  IN_PROGRESS: 'warning',
  FAILED: 'negative',
  ACCOUNT_REQUESTED: 'warning',
  ACCOUNT_UPDATED: 'warning',
  RESCORING: 'ambition',
  APPROVED_PENDING_RETRIEVER: 'warm'
}

const ScoreStyled = styled.span`
  font-weight: bold;
`

const WrapStyled = styled.div`
  ${HeaderCellStyled} {
    padding: 24px 16px 16px;
    font-weight: 400;
  }

  ${BodyCellStyled} {
    padding: 16px;
    font-weight: 400;
  }
`
const UnassignedStyled = styled.span`
  ${fontFamily('Inter')};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey600};
`
const NoDataWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 600px;

  ${ContainerStyled} {
    margin-top: 0;
  }
`
const ApplicationCell = styled.div`
  display: flex;
  flex-direction: column;
`

const BorrowerInfo = styled.div`
  display: flex;
  align-items: center;
`

const BorrowerName = styled.div`
  margin-right: 5px;
`

const Table: FC = () => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  const {
    applicationsQuery: { data, isFetching },
    setSortBy,
    setCurrentPage,
    filter,
    search,
    sortBy
  } = useApplicationsContext()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: isSocialCreditOrganization ? 'Name' : 'Application/Name',
        accessor: (row) => {
          const {
            plaidIdentityCheck,
            dataOneIdentityCheck,
            plaidBorrowerIdentityNames,
            dataOneBorrowerIdentityNames,
            wlcfId,
            id: rowId,
            status,
            borrowerName
          } = row as TApplication
          const id: string | number = wlcfId || rowId
          return (
            <ApplicationCell>
              <BorrowerInfo>
                <BorrowerName>
                  {
                    ['APPROVED', 'DECLINED', 'CANCELLED', 'APPROVED_PENDING_RETRIEVER'].includes(status)
                      ? borrowerName
                      : id
                  }
                </BorrowerName>
                <IdentityIcon
                  iconSize={12}
                  dataOneIdentityCheck={dataOneIdentityCheck}
                  plaidIdentityCheck={plaidIdentityCheck} />
              </BorrowerInfo>

              {
                ['APPROVED', 'DECLINED'].includes(status)
                && (
                  <>
                    <IdentityInfo
                      identityCheck={dataOneIdentityCheck}
                      identityNames={dataOneBorrowerIdentityNames}
                      name="d.One" />
                    <IdentityInfo
                      identityCheck={plaidIdentityCheck}
                      identityNames={plaidBorrowerIdentityNames}
                      name="Plaid" />
                  </>
                )
              }
            </ApplicationCell>
          )
        },
        disableSortBy: true
      },
      {
        Header: 'Plend Score',
        accessor: 'score',
        Cell: ({ value }) => <ScoreStyled>{value}</ScoreStyled>
      },
      {
        Header: 'Source',
        accessor: 'source',
        Cell: ({ value }) => value
      },
      {
        Header: `${sortBy?.id === 'statusAt' ? 'Approval' : 'Application'} Date`,
        accessor: sortBy?.id,
        Cell: ({ value }) => (
          <span>
            {format(new Date(value), SLASH_DATE_FORMAT)}
            {' '}
            {format(new Date(value), HOUR_MINUTE)}
          </span>
        )
      },
      ...(!isSocialCreditOrganization
        ? [{
          Header: 'Owner',
          accessor: 'underwriterName',
          Cell: ({ row: { original } }: any) =>
            (original?.status === 'NEW'
              ? <UnassignedStyled>Unassigned</UnassignedStyled> : <>{original?.underwriterName}</>)
        }]
        : []),
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value }) => (
          <StatusBadge actionMarker={markerMap[value as TStatus]}>
            {value && statusToHumanReadableFormat(value)}
          </StatusBadge>
        )
      },
      {
        Header: 'Reason for reposting',
        accessor: 'repostReason',
        Cell: ({ value }) => <span data-testid="repostReason">{value}</span>
      },
      {
        Header: 'Loan Amount',
        accessor: 'loanAmount',
        Cell: ({ value }) => <span>{formatToPound(value)}</span>
      }
    ],
    [filter, isSocialCreditOrganization]
  )

  const navigate = useNavigate()

  const applications = useMemo(() => data?.content || [], [data])

  if (!data) {
    return (
      <NoDataWrapper>
        <Spinner />
      </NoDataWrapper>
    )
  }

  return (
    <WrapStyled>
      <CustomTable
        key={filter}
        columns={columns}
        showLoader={isFetching}
        data={applications}
        sortingConfig={{
          sortable: true,
          manual: true,
          initialSortBy: sortBy ? [sortBy] : [],
          onSortByChange: (sortBy) => setSortBy(sortBy)
        }}
        paginationConfig={{
          isEnabled: data?.totalPages > 1,
          pageSize: 10,
          manual: true,
          pageCount: data?.totalPages,
          totalRows: data?.totalElements,
          onPageChange: (page) => setCurrentPage(page)
        }}
        onRowClick={
          ({ original }) => (original.wlcfId
            ? navigate((original as TApplication)?.wlcfId.toString())
            : navigate((original as TApplication)?.id.toString()))
        }
        showRowBorder
      />
      {search ? !applications.length && (
        <NoDataWrapper>
          <NoResults />
        </NoDataWrapper>
      )
        : !applications.length && (
          <NoDataWrapper>
            <NoDataComponent iconWidth={52} iconHeight={48} text="There are no applications with this status" />
          </NoDataWrapper>
        )}
    </WrapStyled>
  )
}

export default Table
