import { FC, useCallback, useEffect, useMemo } from 'react'
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  FormControlLabel as Label
} from '@mui/material'

import { useForm, UseFormReturn } from 'react-hook-form'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import { UseMutateAsyncFunction, useQueryClient } from 'react-query'
import { CloseLineIcon } from '../../../../core/components/icons/CloseLineIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import OBSelect from './OBSelect'
import { TForm, TReclassifyParams } from '../types'
import ApplyChanges from './ApplyChanges'
import { BaseButton } from '../../../../core/components/Button'
import { useReclassifyMutation, useTaxonomyQuery } from '../queries'
import { useTransactionsClassesOptionsQuery } from '../../transactions/queries'
import { TTransaction } from '../../transactions/types'

const Dialog = styled.div`
    width: 480px;
    background: ${({ theme }) => theme.colors.white};
    border: none;
    outline: none;
    box-shadow: ${({ theme }) => theme.colors.shadow200};
    border-radius: 6px;
    padding: 24px;
    margin: 0;
`

const DialogTitle = styled(MuiDialogTitle)`
    && {
        ${fontFamily('Inter')};
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: ${({ theme }) => theme.colors.darkBlack};
        padding: 0;
        margin-bottom: 24px;
    }
`

const IconContainerStyled = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.darkBlack};
`

const FormStyled = styled.form`
    ${flex({})};
    flex-direction: column;
    width: 100%;
    gap: 18px;
    margin-bottom: 32px;

    .MuiTypography-root {
        ${fontFamily('Inter')};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
        color: ${({ theme }) => theme.colors.darkBlack};
    }

    .MuiFormControlLabel-root {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin: 0;
    }

`

const TextStyled = styled.div<{ isBold?: boolean }>`
    ${fontFamily('Inter')};
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ isBold }) => (isBold ? 700 : 500)};
    line-height: 20px;
`

const DescriptionStyled = styled.div`
    ${fontFamily('Inter')};
    color: ${({ theme }) => theme.colors.darkBlack};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`

const TextWrapStyled = styled.div`
    ${flex({ align: 'center' })};
    gap: 2px;
`

const ButtonGroupStyled = styled.div`
    ${flex({ justify: 'flex-end', align: 'center' })}
`

const ButtonCancelStyled = styled(BaseButton)`
    && {
        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.darkBlack};
        padding: 8px 16px;
        max-width: 98px;

        &:hover {
            background: ${({ theme }) => theme.colors.grey500};
        }

        &:disabled {
            opacity: .5;
            pointer-events: all;
            cursor: not-allowed;
        }
    }
`

const ButtonActionStyled = styled(BaseButton)`
    && {
        background: ${({ theme }) => theme.colors.blue500};
        color: ${({ theme }) => theme.colors.white};
        padding: 8px 16px;
        margin-left: 18px;

        &:hover {
            background: ${({ theme }) => theme.colors.blue700};
        }

        &:disabled {
            opacity: .5;
            pointer-events: all;
            cursor: not-allowed;
        }
    }
`

function useFormInit() {
  return useForm<TForm>({
    defaultValues: {
      firstClass: '',
      secondClass: '',
      applyToSimilarDescriptions: true
    }
  })
}

function useResetForm(transactionField: TTransaction[], { setValue }: UseFormReturn<TForm>) {
  useEffect(() => {
    const isOneTransaction = transactionField.length === 1

    setValue('firstClass', isOneTransaction ? transactionField[0].taxonomyFirstClass : '')
    setValue('secondClass', isOneTransaction ? transactionField[0].taxonomySecondClass : '')
  }, [transactionField.length])
}

function useOnSubmit(
  transactions: number[],
  mutateAsync: UseMutateAsyncFunction<void, Error, TReclassifyParams, unknown>,
  callback: () => void
) {
  const { loanRequestId } = useParams()
  const queryClient = useQueryClient()
  return useCallback(async (data: TForm) => {
    await mutateAsync({
      ...data,
      loanApplicationId: loanRequestId!,
      transactionIds: transactions
    })
      .then(callback)
      .then(async () => {
        await queryClient.invalidateQueries('HeaderQuery')
        await queryClient.invalidateQueries('Transactions')
        await queryClient.invalidateQueries('Categories')
        await queryClient.invalidateQueries('CategoriesTransactions')
      })
  }, [transactions.length])
}

function useClassesOptions(firstClass: string) {
  const { data: firstClassData } = useTransactionsClassesOptionsQuery(
    'first',
    '',
    '',
    true
  )
  const { data: secondClassData } = useTransactionsClassesOptionsQuery(
    'second',
    firstClass,
    '',
    true
  )
  return {
    firstClassOptions: useMemo(() => {
      if (!firstClassData) {
        return []
      }
      return firstClassData.firstClasses.map((name: string) => ({ value: name, label: name }))
    }, [firstClassData]),
    secondClassOptions: useMemo(() => {
      if (!secondClassData) {
        return []
      }
      return secondClassData.secondClasses.map((name: string) => ({ value: name, label: name }))
    }, [secondClassData])
  }
}

type TProps = {
  isOpen: boolean
  handleClose: () => void
  transactions: TTransaction[]
  handleClearTransactions: () => void
}
const EditModal: FC<TProps> = ({ isOpen, handleClose, transactions, handleClearTransactions }) => {
  const { mutateAsync, isLoading } = useReclassifyMutation()
  const formReturn = useFormInit()
  useResetForm(transactions, formReturn)
  const { control, handleSubmit, watch, setValue } = formReturn
  const { firstClass, secondClass } = watch()
  const { data: taxonomyData } = useTaxonomyQuery({ firstClass, secondClass })
  const onSubmit = useOnSubmit(
    transactions.map(({ id }) => id),
    mutateAsync,
    () => {
      handleClearTransactions()
      handleClose()
    }
  )
  const { firstClassOptions, secondClassOptions } = useClassesOptions(firstClass)

  const description = useMemo(
    () => Array.from(new Set(transactions.map(({ name }) => name)))
      .join(', '),
    [transactions.length]
  )

  if (!isOpen) {
    return <></>
  }
  return (
    <MuiDialog
      sx={{ overflow: 'hidden' }}
      open={isOpen}
      onClose={handleClose}
    >
      <Dialog>
        <DialogTitle>Edit transactions</DialogTitle>
        <IconContainerStyled onClick={handleClose}>
          <CloseLineIcon />
        </IconContainerStyled>
        <FormStyled id="editTransactionForm" onSubmit={handleSubmit(onSubmit)}>
          <DescriptionStyled>{description}</DescriptionStyled>
          <Label
            control={(
              <OBSelect
                reset={() => setValue('secondClass', '')}
                control={control}
                name="firstClass"
                options={firstClassOptions} />
            )}
            label="Change OB Class 1 to"
            labelPlacement="top"
          />

          <Label
            control={(
              <OBSelect
                disabled={!firstClass}
                control={control}
                name="secondClass"
                options={secondClassOptions} />
            )}
            label="Change OB Class 2 to"
            labelPlacement="top"
          />

          {
            taxonomyData
              ? (
                <TextWrapStyled>
                  <TextStyled>
                    FCA Category -
                  </TextStyled>
                  <TextStyled isBold>
                    {taxonomyData.fcaCategory}
                  </TextStyled>
                </TextWrapStyled>
              )
              : null
          }

          <ApplyChanges control={control} />
        </FormStyled>
        <ButtonGroupStyled>
          <ButtonCancelStyled onClick={handleClose}>Cancel</ButtonCancelStyled>
          <ButtonActionStyled
            disabled={(!firstClass || !secondClass) || isLoading}
            type="submit"
            form="editTransactionForm">
            Apply
          </ButtonActionStyled>
        </ButtonGroupStyled>
      </Dialog>
    </MuiDialog>
  )
}

export default EditModal
