import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { FileListIcon } from '../../../core/components/icons/FileListIcon'
import { DashboardIcon } from '../../../core/components/icons/DashboardIcon'
import { ExclamationIcon } from '../../../core/components/icons/ExclamationIcon'
import {
  useIsCurrentOrganization,
  useHasPermission,
  useIsPlendOrganization,
  useIsSocialCreditOrganization
} from '../../auth/hooks'
import { KeywordsIcon } from '../../../core/components/icons/KeywordsIcon'
import { ValidationRulesIcon } from '../../../core/components/icons/ValidationRulesIcon'
import { ScoringConfIcon } from '../../../core/components/icons/ScoringConfIcon'
import { UsersManagementIcon } from '../../../core/components/icons/UsersManagementIcon'
import { FailedIcon } from '../../../core/components/icons/FailedIcon'
import { PercentageIcon } from '../../../core/components/icons/PercentageIcon'

export const sidebarWidth = 192

const ContainerStyled = styled.div`
  ${flex({ align: 'left' })};
  flex-direction: column;
  width: ${sidebarWidth}px;
  position: relative;
  z-index: 20;
  box-shadow: ${({ theme }) => theme.colors.shadow200};
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  padding-top: 32px;
`

const CustomNavlinkStyled = styled(NavLink)`
  ${fontFamily('Inter')};
  ${flex({})};
  padding: 10px 0 10px 15px !important;

  &:hover {
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey300};
  }

  &[class*="active"] {
    background: ${({ theme }) => theme.colors.lightBlue};

    span {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.darkBlack};
    }
  }

  & > span {
    ${flex({ justify: 'center', align: 'center' })};
    color: ${({ theme }) => theme.colors.grey600};
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;

    :first-child {
      margin-right: 8px;
    }
  }
`

const Sidebar = () => {
  const isOrganization = useHasPermission(['ORGANIZATION_ADMIN_VIEW'])
  const isPlendOrganization = useIsPlendOrganization()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const enableFailedValidation = !useIsCurrentOrganization([
    'lendology',
    'purpleshoots',
    'robertowen'
  ]) && !isSocialCreditOrganization

  return (
    <ContainerStyled>
      {!useHasPermission(['GENERAL_VIEW', 'PARTNERS_ADMIN_VIEW', 'PARTNERS_EMPLOYEE_VIEW']) && (
        <CustomNavlinkStyled end to="home">
          <DashboardIcon />
          <span>Home</span>
        </CustomNavlinkStyled>
      )}
      {useHasPermission(['UNDERWRITER_VIEW', 'CUSTOMER_SERVICE_VIEW']) && (
        <>
          <CustomNavlinkStyled end to="applications">
            <FileListIcon />
            <span>Applications</span>
          </CustomNavlinkStyled>
          {enableFailedValidation && (
            <CustomNavlinkStyled end to="failed-validation">
              <ExclamationIcon />
              <span>Failed Validations</span>
            </CustomNavlinkStyled>
          )}
          {isSocialCreditOrganization && (
            <CustomNavlinkStyled end to="declined-details">
              <FailedIcon />
              <span>Declined details</span>
            </CustomNavlinkStyled>
          )}
        </>
      )}
      {isOrganization && (
        <>
          {isPlendOrganization && (
            <CustomNavlinkStyled end to="keywords">
              <KeywordsIcon />
              <span>Keywords</span>
            </CustomNavlinkStyled>
          )}
          {(isPlendOrganization || isSocialCreditOrganization) && (
            <>
              <CustomNavlinkStyled end to="validation-rules">
                <ValidationRulesIcon />
                <span>Validation Rules</span>
              </CustomNavlinkStyled>
              <CustomNavlinkStyled end to="scoring-configuration">
                <ScoringConfIcon />
                <span>Scoring Config</span>
              </CustomNavlinkStyled>
            </>
          )}
        </>
      )}
      {
        useHasPermission(['PARTNERS_EMPLOYEE_VIEW']) && (
          <CustomNavlinkStyled end to="partner-dashboard">
            <UsersManagementIcon width={21} height={21} />
            <span>Partner dashboard</span>
          </CustomNavlinkStyled>
        )
      }
      {useHasPermission(['GENERAL_VIEW', 'ORGANIZATION_ADMIN_VIEW', 'PARTNERS_ADMIN_VIEW']) && (
        <CustomNavlinkStyled end to="user-management">
          <UsersManagementIcon width={21} height={21} />
          <span>User Management</span>
        </CustomNavlinkStyled>
      )}

      {isPlendOrganization && isOrganization && (
        <CustomNavlinkStyled end to="eligibility">
          <PercentageIcon width={21} height={21} />
          <span>Eligibility</span>
        </CustomNavlinkStyled>
      )}
    </ContainerStyled>
  )
}

export default Sidebar
