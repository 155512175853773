import React, { FC } from 'react'
import styled, { css } from 'styled-components/macro'
import { tr } from 'date-fns/locale'
import CustomTooltip from '../../../core/components/CustomTooltip'
import { PersonIcon } from '../../../core/components/icons/PersonIcon'
import { flex, fontFamily } from '../../../core/styles/mixins'

export const textMaxWidth = 114

const InfoContentStyled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey600};
`

const SectionCardStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'start' })};
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grey600};

  & > div {
    ${flex({ justify: 'flex-start', align: 'flex-start' })};
  }
`

const TitleStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: 500;
  margin-left: 4px;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
`

const ValueStyled = styled.div<{ noOverlap?: boolean }>`
  ${fontFamily('Inter')};
  margin: 2px 2px 0 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkBlack};

  max-width: 158px;
  ${({ noOverlap }) => !noOverlap && css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${textMaxWidth}px;
  `}
}
`
const IconWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey600};
`

type TProps = {
  title: string
  icon: JSX.Element
  value?: string | JSX.Element
  isInputByUser?: boolean
  valueComponent?: JSX.Element
  noOverlap?: boolean
}

const titleTooltip = 'Input by user'

const UserInfoItem: FC<TProps> = ({
  title,
  icon,
  value,
  isInputByUser,
  valueComponent, noOverlap
}) => (
  <InfoContentStyled>
    {icon}
    <SectionCardStyled>
      <TitleStyled>{title}</TitleStyled>
      <div>
        {valueComponent || (
          <ValueStyled noOverlap={!!noOverlap}>
            {value}
          </ValueStyled>
        )}
        {isInputByUser
          && (
            <CustomTooltip
              arrow
              disableFocusListener
              disableTouchListener
              title={titleTooltip}
              placement="top">
              <IconWrapper>
                <PersonIcon />
              </IconWrapper>
            </CustomTooltip>
          )}
      </div>
    </SectionCardStyled>
  </InfoContentStyled>
)

export default UserInfoItem
