import { useCallback } from 'react'
import constate from 'constate'
import { useSetState } from 'react-use'
import { TApplicationState, TFilter } from '../types'
import { useApplicationsQuery } from '../queries'
import { TSortBy } from '../../../../core/components/table/TableContext'

const initialState: TApplicationState = {
  search: '',
  currentPage: 1,
  sortBy: getInitialSortBy()
}

function getInitialSortBy(filter?: TFilter) {
  return {
    desc: true,
    id: filter === 'APPROVED' ? 'statusAt' : 'createdAt'
  }
}

const useApplications = () => {
  const [
    applicationListState,
    setApplicationListState
  ] = useSetState<TApplicationState>(initialState)

  const handleChangeFilter = useCallback((filter?: TFilter) => {
    setApplicationListState({
      ...initialState,
      filter,
      sortBy: getInitialSortBy(filter)
    })
  }, [])

  const setSortBy = useCallback((sort?: TSortBy[]) => {
    const { filter } = applicationListState
    setApplicationListState({
      sortBy: sort?.length ? sort[0] : getInitialSortBy(filter)
    })
  }, [applicationListState.filter])

  const setSearch = useCallback((search: string) => setApplicationListState({ search }), [])

  const setCurrentPage = useCallback(
    (currentPage: number) => setApplicationListState({ currentPage }),
    []
  )

  return {
    ...applicationListState,
    setSortBy,
    setSearch,
    setCurrentPage,
    applicationsQuery: useApplicationsQuery(applicationListState),
    handleChangeFilter
  }
}

export const [ApplicationsProvider, useApplicationsContext] = constate(useApplications)
