import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { ApplicationDateIcon } from '../../../core/components/icons/ApplicationDateIcon'
import { AnnualIncomeIcon } from '../../../core/components/icons/AnnualIncomeIcon'
import { EmploymentTypeIcon } from '../../../core/components/icons/EmploymentTypeIcon'
import { LivingStatusIcon } from '../../../core/components/icons/LivingStatusIcon'
import { BirthdayIcon } from '../../../core/components/icons/BirthdayIcon'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { useApplicationHeaderContext } from '../state/useApplicationHeader'
import { SLASH_DATE_FORMAT } from '../../../core/const/date'
import { EmployerIcon } from '../../../core/components/icons/EmployerIcon'
import { LoanPurposeIcon } from '../../../core/components/icons/LoanPurposeIcon'
import { useHasCurrentUserCustomerServiceRole, useHasPermission, useIsPlendOrganization } from '../../auth/hooks'
import { MailIcon } from '../../../core/components/icons/MailIcon'
import { ArrowUpIcon } from '../../../core/components/icons/ArrowUpIcon'
import { ArrowDownIcon } from '../../../core/components/icons/ArrowDownIcon'
import UserExtraInfo from './UserExtraInfo'
import { formattedPhone } from '../utils/phoneUtils'
import UserInfoItem, { textMaxWidth } from './UserInfoItem'
import { LoanTermIcon } from '../../../core/components/icons/LoanTermIcon'
import CustomTooltip from '../../../core/components/CustomTooltip'
import { FileCopyIcon } from '../../../core/components/icons/FileCopyIcon'
import { LoanAmountIcon } from '../../../core/components/icons/LoanAmountIcon'
import { useHandleCopy } from '../../../core/hooks/useHandleCopy'
import MarkApplication from './MarkApplication'

const InfoWrapperStyled = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    flex-direction: column;
    margin-right: 16px;
`

const InfoContainerStyled = styled.div<{ isPlendOrganization?: boolean }>`
    display: grid;
    grid-template-columns: repeat(3, minmax(90px, 150px));
    grid-template-rows: 34px;
    min-width: 464px;
    max-width: 504px;
    grid-gap: 10px 24px;
    padding-right: 16px;
`

const ShowMoreStyled = styled.div`
    margin: 10px 0 10px 0;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.blue500};

    & > div {
        ${flex({ align: 'center' })};
    }
`
const IconWrapper = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey600};
`

const TextStyled = styled.div`
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
`

const ContentStyled = styled.div<{ inColumn?: boolean }>`
    ${flex({})};
    ${fontFamily('Inter')};
    margin: ${({ inColumn }) => `2px 2px 0 ${inColumn ? -16 : 4}px`};
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkBlack};
    gap: 4px;

    & > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: ${textMaxWidth}px;
    }
`

const EmployerTextStyled = styled.span<{ isCursorAuto: boolean }>`
    cursor: ${({ isCursorAuto }) => (isCursorAuto ? 'auto' : 'pointer')};
`

const useShowMore = () => {
  const [isShowMore, setIsShowMore] = useState(false)
  const ShowMoreIcon = useMemo(
    () => (isShowMore ? ArrowUpIcon : ArrowDownIcon),
    [isShowMore]
  )
  return {
    isShowMore,
    setIsShowMore,
    ShowMoreIcon
  }
}

const useEmployerTooltip = (employerTextRef: MutableRefObject<HTMLSpanElement | null>) => {
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(true)
  useEffect(() => {
    if (!employerTextRef.current) {
      return
    }
    if (employerTextRef.current.offsetWidth === textMaxWidth) {
      setIsTooltipDisabled(false)
    }
  }, [employerTextRef, headerData?.borrowerEmployer])

  return isTooltipDisabled
}

const UserInfo = () => {
  const employerTextRef = useRef<HTMLSpanElement | null>(null)
  const { setIsShowMore, isShowMore, ShowMoreIcon } = useShowMore()
  const { headerQuery: { data: headerData, isSuccess } } = useApplicationHeaderContext()
  const isEmployerTooltipDisabled = useEmployerTooltip(employerTextRef)
  const handleCopy = useHandleCopy()

  const isCustomerService = useHasCurrentUserCustomerServiceRole()
  const isUnderwriter = useHasPermission(['UNDERWRITER_VIEW'])
  const isPlendOrganization = useIsPlendOrganization()

  if (!isSuccess || !headerData) {
    return <></>
  }

  const {
    createdAt,
    borrowerEmploymentStatus,
    borrowerLivingStatus,
    borrowerDateOfBirth,
    borrowerIncomeAnnual,
    borrowerEmployer,
    borrowerEmail,
    primaryPhoneNumber,
    loanLength,
    loanPurpose,
    repostReason,
    loanAmount
  } = headerData

  const isShowEmployer = ['FULL_TIME', 'PART_TIME'].includes(borrowerEmploymentStatus?.code ?? '')
  const isEmail = ([
    'APPROVED',
    'DECLINED',
    'CANCELLED',
    'APPROVED_PENDING_RETRIEVER'
  ].includes(headerData.status) && isUnderwriter)

  return (
    <InfoWrapperStyled>
      <InfoContainerStyled isPlendOrganization={isPlendOrganization}>
        <UserInfoItem
          title="Application Date"
          value={createdAt ? format(new Date(createdAt), SLASH_DATE_FORMAT) : '-'}
          icon={<ApplicationDateIcon />}
        />
        <UserInfoItem
          title="Annual Income"
          value={borrowerIncomeAnnual?.toString() ? formatToPound(borrowerIncomeAnnual) : '-'}
          icon={<AnnualIncomeIcon />}
          isInputByUser
        />
        <UserInfoItem
          title="Employment Type"
          icon={<EmploymentTypeIcon />}
          value={borrowerEmploymentStatus?.value || '-'}
          isInputByUser
        />
        <UserInfoItem
          title="Living Status"
          icon={<LivingStatusIcon />}
          value={borrowerLivingStatus?.value || '-'}
          isInputByUser
        />
        <UserInfoItem
          title="Date of Birth"
          icon={<BirthdayIcon />}
          value={borrowerDateOfBirth ? format(new Date(borrowerDateOfBirth), SLASH_DATE_FORMAT) : '-'}
          isInputByUser
        />
        <UserInfoItem
          title="Loan Purpose"
          icon={<LoanPurposeIcon />}
          value={repostReason || loanPurpose || '-'}
          isInputByUser
        />
        <UserInfoItem
          title="Employer"
          icon={<EmployerIcon />}
          isInputByUser
          valueComponent={(
            <ContentStyled>
              <CustomTooltip
                arrow
                disableFocusListener
                disableTouchListener
                title={isShowEmployer ? borrowerEmployer || '' : ''}
                disableHoverListener={isEmployerTooltipDisabled}
                placement="top">
                <EmployerTextStyled
                  isCursorAuto={isEmployerTooltipDisabled}
                  ref={employerTextRef}
                >
                  {isShowEmployer ? borrowerEmployer || '' : ''}
                </EmployerTextStyled>
              </CustomTooltip>
            </ContentStyled>
          )}
        />
        {isCustomerService && (
          <>
            <UserInfoItem
              title="Phone"
              icon={<EmployerIcon />}
              isInputByUser
              valueComponent={(
                <ContentStyled inColumn={!!primaryPhoneNumber}>
                  {isCustomerService
                    && <MarkApplication iconWidthHeight={16} width={340} match={['PHONE']} placement="top" />}
                  {
                    primaryPhoneNumber
                      ? <span>{formattedPhone(primaryPhoneNumber)}</span>
                      : '-'
                  }
                </ContentStyled>
              )}
            />
            <UserInfoItem
              title="Email"
              icon={<MailIcon width={16} height={16} />}
              valueComponent={(
                <ContentStyled inColumn>
                  {isCustomerService
                    && <MarkApplication iconWidthHeight={16} width={340} match={['EMAIL']} placement="top" />}
                  <span>{borrowerEmail || '-'}</span>
                  <CustomTooltip
                    arrow
                    disableFocusListener
                    disableTouchListener
                    title="Copy Email"
                    placement="top">
                    <IconWrapper>
                      <FileCopyIcon onClick={() => handleCopy(borrowerEmail)} />
                    </IconWrapper>
                  </CustomTooltip>
                </ContentStyled>
              )}
            />
          </>
        )}
        {((isCustomerService && isShowMore) || !isCustomerService) && (
          <>
            <UserInfoItem
              title="Loan Term"
              icon={<LoanTermIcon />}
              value={`${loanLength?.toString() ? loanLength : '-'} months`}
            />
          </>
        )}
        {
          isEmail && isPlendOrganization && (
            <UserInfoItem
              title="Email"
              icon={<MailIcon width={16} height={16} />}
              valueComponent={(
                <ContentStyled>
                  {
                    borrowerEmail ? (
                      <CustomTooltip placement="top" arrow title={borrowerEmail}>
                        <span>{borrowerEmail || '-'}</span>
                      </CustomTooltip>
                    )
                      : <span>-</span>
                  }

                  <CustomTooltip
                    arrow
                    disableFocusListener
                    disableTouchListener
                    title="Copy Email"
                    placement="top">
                    <IconWrapper>
                      <FileCopyIcon onClick={() => handleCopy(borrowerEmail)} />
                    </IconWrapper>
                  </CustomTooltip>
                </ContentStyled>
              )}
            />
          )
        }
        {((!isCustomerService && isShowMore) || isCustomerService) && (
          <UserInfoItem
            title="Loan Amount"
            icon={<LoanAmountIcon />}
            value={loanAmount?.toString() ? formatToPound(loanAmount) : '-'}
          />
        )}
        {isShowMore && <UserExtraInfo />}
      </InfoContainerStyled>
      <ShowMoreStyled onClick={() => setIsShowMore(!isShowMore)}>
        <div>
          <ShowMoreIcon width={10} height={6} />
          <TextStyled>{isShowMore ? 'Show less' : 'Show more'}</TextStyled>
        </div>
      </ShowMoreStyled>
    </InfoWrapperStyled>
  )
}
export default UserInfo
