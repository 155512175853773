import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Column } from 'react-table'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import CustomTable from '../../../../core/components/table/Table'
import { TStatus } from '../types'
import StatusBadge, { TActionMarker } from '../../../../core/components/StatusBadge'
import { useFailedValidationContext } from '../state/useFailedValidationContext'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import { statusToHumanReadableFormat } from '../../../../core/utils/statusToHumanReadableFormat'
import Spinner from '../../../../core/components/Spinner'
import NoDataComponent, { ContainerStyled } from '../../../../core/components/NoDataComponent'
import { flex } from '../../../../core/styles/mixins'
import { HOUR_MINUTE, SLASH_DATE_FORMAT } from '../../../../core/const/date'
import { TApplication } from '../../../applications/applicationsList/types'
import NoResults from '../../../../core/components/NoResults'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'

const markerMap: Record<TStatus, TActionMarker> = {
  MAIN_ACCOUNT_VALIDATION: 'neutral',
  OB_DECLINES_VALIDATION: 'warning',
  DEMOGRAPHIC_AND_BUREAUX_VALIDATION: 'negative',
  JAYWING_VALIDATION: 'calm',
  BORROWER_AGE_VALIDATION: 'positive',
  OTHER_SOCIAL_LOAN_VALIDATION_IN_THE_DB: 'warm',
  WELSH_POSTCODE_VALIDATION: 'ambition',
  ENGLISH_SCOTTISH_POSTCODE_VALIDATION: 'ambition'
}

const WrapStyled = styled.div`
    height: 100%;

    ${HeaderCellStyled} {
        padding: 24px 16px 16px;
        font-weight: 400;
    }

    ${BodyCellStyled} {
        padding: 16px;
        font-weight: 400;

        :first-child {
            width: 25%;
        }

        :nth-child(2) {
            width: 15%;
        }

        :nth-child(3) {
            width: 15%;
        }

        :nth-child(4) {
            width: 25%;
        }

        :last-child {
            width: 10%;
        }
    }
}
`

const NoDataWrapper = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    height: 600px;

    ${ContainerStyled} {
        margin-top: 0;
    }
`

const SpinnerWrapper = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    height: 650px;
`

export const Table: FC = () => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'borrowerName',
        disableSortBy: true,
        Cell: ({ value }) => <span data-testid="borrowerName">{value}</span>
      },
      {
        Header: 'Source',
        accessor: 'source',
        Cell: ({ value }) => <span data-testid="source">{value}</span>
      },
      {
        Header: 'Application Date',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <span data-testid="createdAt">
            {format(new Date(value), SLASH_DATE_FORMAT)}
            {' '}
            {format(new Date(value), HOUR_MINUTE)}
          </span>
        )
      },
      {
        Header: 'Failed Validation Step',
        accessor: 'failedValidation',
        Cell: ({ value }) => {
          const failedStep = markerMap[value as TStatus]
          return (
            <>
              {failedStep
                && (
                  <span data-testid="failedValidation">
                    <StatusBadge actionMarker={markerMap[value as TStatus]}>
                      {value && statusToHumanReadableFormat(value)}
                    </StatusBadge>
                  </span>
                )}
            </>
          )
        }
      },
      {
        Header: 'Reason for reposting',
        accessor: 'repostReason',
        Cell: ({ value }) => <span data-testid="repostReason">{value}</span>
      },
      {
        Header: 'Loan Amount',
        accessor: 'loanAmount',
        Cell: ({ value }) => <span data-testid="loanAmount">{formatToPound(value)}</span>
      }
    ],
    [isSocialCreditOrganization]
  )

  const navigate = useNavigate()

  const {
    failedValidationQuery: { data, isLoading, isIdle, isFetching },
    sortBy,
    setCurrentPage,
    setSortBy,
    search
  } = useFailedValidationContext()

  const failedValidation = useMemo(() => data?.content || [], [data])

  if (!data) {
    return (
      <SpinnerWrapper data-testid="spinner">
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <WrapStyled>
      <CustomTable
        columns={columns}
        data={failedValidation}
        showLoader={isFetching}
        sortingConfig={{
          sortable: true,
          manual: true,
          initialSortBy: sortBy && [sortBy],
          onSortByChange: (sortBy) => setSortBy(sortBy)
        }}
        paginationConfig={{
          isEnabled: data?.totalPages > 1,
          pageSize: 10,
          manual: true,
          totalRows: data?.totalElements,
          pageCount: data?.totalPages,
          onPageChange: (page) => setCurrentPage(page)
        }}
        onRowClick={
          ({ original }) => (original.wlcfId
            ? navigate((original as TApplication)?.wlcfId.toString())
            : navigate((original as TApplication)?.id.toString()))
        }
        showRowBorder
      />
      {search
        ? !failedValidation.length && !isLoading && !isIdle && (
          <NoDataWrapper data-testid="noResults">
            <NoResults />
          </NoDataWrapper>
        )
        : !failedValidation.length && !isLoading && !isIdle && (
          <NoDataWrapper data-testid="noApplications">
            <NoDataComponent
              iconWidth={52}
              iconHeight={48}
              text="There are no applications to display"
            />
          </NoDataWrapper>
        )}
    </WrapStyled>
  )
}

export default Table
