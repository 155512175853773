import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { PercentageIcon } from '../../../core/components/icons/PercentageIcon'
import { MonthlyRepaymentIcon } from '../../../core/components/icons/MonthlyRepaymentIcon'
import { EmployerIcon } from '../../../core/components/icons/EmployerIcon'
import { useApplicationHeaderContext } from '../state/useApplicationHeader'
import { formattedPhone } from '../utils/phoneUtils'
import UserInfoItem from './UserInfoItem'
import { useHasCurrentUserCustomerServiceRole, useIsPlendOrganization } from '../../auth/hooks'
import { fontFamily } from '../../../core/styles/mixins'
import { DeclineCircledFilledIcon } from '../../../core/components/icons/DeclineCircledFilledIcon'
import { prepareDeclineReasonLabel } from '../../applications/applicationDetail/utils/prepareDeclineReasonLabel'
import AddressInformation from './AddressInformation'

const TextStyled = styled.div<{ width?: number }>`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-left: 4px;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`

const IconWrapStyled = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
`
const ButtonStyled = styled.button`
  all: unset;
  ${fontFamily('Inter')};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue500};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`

function useAdditionalDeclineReasons() {
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const [isShowMoreDeclineDetails, setIsShowMoreDeclineDetails] = useState(false)
  const preparedAdditionalDeclineReason = useMemo(() => {
    if (!headerData) {
      return []
    }
    return headerData.additionalDeclineReasons.map(({ value }) => value)
  }, [headerData])
  return {
    declineDetails: useMemo(
      () => {
        const declineReasonLabel = prepareDeclineReasonLabel(headerData?.declineReason?.value)
        const preparedDeclineDetails = isShowMoreDeclineDetails
          ? preparedAdditionalDeclineReason
          : preparedAdditionalDeclineReason.slice(0, 2)
        return preparedDeclineDetails.length
          ? `${declineReasonLabel} - ${preparedDeclineDetails.join(', ')}`
          : declineReasonLabel
      },
      [preparedAdditionalDeclineReason, isShowMoreDeclineDetails]
    ),
    preparedAdditionalDeclineReason,
    isShowMoreDeclineDetails,
    setIsShowMoreDeclineDetails
  }
}

const UserExtraInfo = () => {
  const { headerQuery: { data: headerData, isSuccess } } = useApplicationHeaderContext()
  const isCustomerService = useHasCurrentUserCustomerServiceRole()
  const isPlendOrganization = useIsPlendOrganization()
  const {
    declineDetails,
    preparedAdditionalDeclineReason,
    setIsShowMoreDeclineDetails,
    isShowMoreDeclineDetails
  } = useAdditionalDeclineReasons()
  if (!isSuccess || !headerData) {
    return <></>
  }
  const {
    interestRate,
    monthlyRepayment,
    secondaryPhoneNumber,
    cancelReason, status
  } = headerData
  const isStatusCancelled = status === 'CANCELLED'

  return (
    <>
      <UserInfoItem
        title={isPlendOrganization ? 'Fixed Rate' : 'Loan APR'}
        icon={<PercentageIcon />}
        value={interestRate?.toString() ? `${interestRate}%` : '-'}
      />
      <UserInfoItem
        title="Monthly Repayment"
        icon={<MonthlyRepaymentIcon />}
        value={monthlyRepayment?.toString() ? formatToPound(monthlyRepayment) : '-'}
      />
      {
        !!declineDetails && (
          <UserInfoItem
            title="Decline details"
            icon={(
              <IconWrapStyled>
                <DeclineCircledFilledIcon width={16} height={16} />
              </IconWrapStyled>
            )}
            valueComponent={(
              <TextStyled width={150}>
                {declineDetails}
                {' '}
                {preparedAdditionalDeclineReason.length > 2 && (
                  <ButtonStyled
                    onClick={() => setIsShowMoreDeclineDetails((prevState) => !prevState)}>
                    {isShowMoreDeclineDetails ? 'less' : 'more...'}
                  </ButtonStyled>
                )}
              </TextStyled>
            )}
          />
        )
      }

      {isCustomerService && (
        <UserInfoItem
          title="Secondary Phone"
          icon={<EmployerIcon />}
          value={secondaryPhoneNumber ? formattedPhone(secondaryPhoneNumber) : '-'}
          isInputByUser
        />
      )}
      {
        cancelReason && isStatusCancelled && (
          <UserInfoItem
            title="Cancel Reason"
            icon={(
              <IconWrapStyled>
                <DeclineCircledFilledIcon width={16} height={16} />
              </IconWrapStyled>
            )}
            valueComponent={<TextStyled>{cancelReason.value}</TextStyled>}
          />
        )
      }
      <AddressInformation />
    </>
  )
}

export default UserExtraInfo
